"use client";
import React from 'react';
const RefundPolicy = () => {
    return (
        <div className='page-content'>

            <div className="about-area-three bg-image " style={{ background: '#CBBBDD', paddingTop: '220px', paddingBottom: '3%' }}>
                <div className="container">
                    <div className="row h-100 align-items-center refund-policy">
                        <div className="col-lg-12 refund-contact">
                            <div className="section-title refund-text refund-policy-contact-box">
                                <h2 className='refund-heading'>Cancellation and Refund Policy</h2>
                                {/* <div className="bar"></div> */}
                                <ol>
                                    <li>We collect an advance amount of ₹750 towards the confirmation of your booking. If you opt for a cake, we will collect an additional advance amount of ₹750 plus the cost of the cake.</li>
                                    <li>If you cancel your slot 3 days (72 hours) prior to your booking, a cancellation charge of ₹500 will apply. If you cancel after that, the charge will be ₹750</li>
                                    <li>Please note that if you cancel on the day of the occasion, there will be no refund for either the slot or the cake.</li>
                                    <li>The payment will be refunded back to the same mode of payment used by the customer.</li>
                                    <li>If you need to cancel your booking, please contact us as soon as possible through WhatsApp at (+91 8121697022) so we can assist you with your cancellation. We will process your refund within 7 business days of receiving your cancellation request.</li>
                                    <li>If you have any questions or concerns about our refund policy, please do not hesitate to contact us. We are always happy to help!</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RefundPolicy;
